<template>
  <div>
    <b-navbar
      style="max-height: 2.5rem; background-color: #444 !important"
      toggleable="lg"
      type="dark"
      variant="dark"
    >
      <b-container>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse
          style="z-index: 1000;margin-top: -0.5rem;"
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav style="background-color: #444" class="text-white">
            <b-nav-item to="/">Startseite</b-nav-item>
            <b-nav-item to="/callcenter"
              >Auftragsannahme<NewBadge eol="2023-03-14"></NewBadge
            ></b-nav-item>
            <b-nav-item-dropdown
              style="border-radius: 0"
              text="Auftragsverwaltung"
            >
              <b-dropdown-item to="/workView">Arbeitsansicht</b-dropdown-item>
              <b-dropdown-item to="/all-orders">Alle Aufträge</b-dropdown-item>
              <b-dropdown-item to="/cancelled-orders"
                >Stornierte Aufträge</b-dropdown-item
              >
              <hr v-if="this.$user.admin == 1" />
              <b-dropdown-item v-if="this.$user.admin == 1" to="/job-money-transfer"
                >Auftrags&uuml;berweisungen</b-dropdown-item
              >
              <b-dropdown-item v-if="this.$user.admin == 1" to="/job-money-transfer-cancelled"
                >Ausbuchungen</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto" style="background-color: #444;">
            <b-nav-item v-if="this.$user.admin == 1" to="/accounting">
              Buchhaltung
            </b-nav-item>
            <b-nav-item-dropdown v-if="this.$user.admin == 1" text="Verwaltung">
              <b-dropdown-item to="/management/customer">Kunden</b-dropdown-item>
              <b-dropdown-item to="/management/employee">Monteure</b-dropdown-item>
              <b-dropdown-item to="/management/mediators">Zentralen</b-dropdown-item>
              <b-dropdown-item to="/management/contractTypes">Einsatztypen</b-dropdown-item>
              <b-dropdown-item to="/management/user">Benutzer</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item @click="openSearch"
              ><b-icon-search></b-icon-search
            ></b-nav-item>
            <b-nav-item-dropdown text="Benutzer">
              <template v-slot:button-content>
                <b-icon-person-fill></b-icon-person-fill>
              </template>
              <b-dropdown-item disabled>{{
                this.$user.username
              }}</b-dropdown-item>
              <b-dropdown-item to="/user/settings"
                >Einstellungen</b-dropdown-item
              >
              <b-dropdown-item to="/logout">Abmelden</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item to="/notifications"
              ><b-icon-bell></b-icon-bell
              ><NotificationBadge
                :items="notificationCount"
              ></NotificationBadge>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import { ref, watch } from "vue";
var instance;
import NewBadge from "@/components/objcs/NewBadge.vue";
import NotificationBadge from "@/components/objcs/NotificationBadge.vue";
export default {
  name: "Navigation",
  components: {
    NewBadge,
    NotificationBadge,
  },
  data: function () {
    return {
      timer: -200,
      search: this.$search,
      notificationCount: "0",
    };
  },
  methods: {
    openSearch: function (event) {
      instance.$root.search = true;
    },
    getNotificationCount: function () {
      this.$root.apiRequest("/notification", {}, (response) => {
        this.notificationCount = response.data.result.count;
      }, undefined, true);
    },
  },
  mounted() {
    instance = this;
    this.getNotificationCount();
    this.timer = setInterval(function() {
      instance.getNotificationCount();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: contents !important;
  }
} 
.dropdown-menu {
  border-radius: 0 0 10px 10px !important;
  background-color: #444 !important;
}
.dropdown-item {
  color: white !important;
}
</style>